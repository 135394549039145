import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/wqmatadourobovinos.css'
import pal1 from "../images/palestrantes/pj-budler.png" // Tell webpack this JS file uses this image
import pal2 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import pal3 from "../images/palestrantes/quim.jpg" // Tell webpack this JS file uses this image
import pal4 from "../images/palestrantes/phill.jpg" // Tell webpack this JS file uses this image
import pal5 from "../images/palestrantes/roberto.jpg" // Tell webpack this JS file uses this image
import pal6 from "../images/palestrantes/stilwell.jpg" // Tell webpack this JS file uses this image
import pal7 from "../images/palestrantes/rui.jpg" // Tell webpack this JS file uses this image
import pal8 from "../images/palestrantes/xavier.jpg" // Tell webpack this JS file uses this image
import pal9 from "../images/palestrantes/fabio.jpg" // Tell webpack this JS file uses this image
import pal10 from "../images/palestrantes/xenia.jpg" // Tell webpack this JS file uses this image
import pal11 from "../images/palestrantes/fabio2.jpg" // Tell webpack this JS file uses this image
import pal12 from "../images/palestrantes/aida.jpg" // Tell webpack this JS file uses this image
import pal13 from "../images/palestrantes/abuelo.png" // Tell webpack this JS file uses this image
import pal15 from "../images/palestrantes/cavalcanti.png" // Tell webpack this JS file uses this image

import pal14 from "../images/palestrantes/bart.png" // Tell webpack this JS file uses this image

import fotomasterclass from "../images/imagem-masterclass.jpg" // Tell webpack this JS file uses this image
import Form from '../components/Form'
import newsletter from "../images/newsletter-img.png" // Tell webpack this JS file uses this image
import Footer from "../components/footer"
import pal33 from "../images/palestrantes/diogogama.png" // Tell webpack this JS file uses this image
import pal35 from "../images/palestrantes/martinez.png" // Tell webpack this JS file uses this image

import logo1 from "../images/logos/irta.png" // Tell webpack this JS file uses this image
import logo2 from "../images/logos/wq.png" // Tell webpack this JS file uses this image
import logo3 from "../images/logos/georgia.png" // Tell webpack this JS file uses this image
import logo4 from "../images/logos/texastech.png" // Tell webpack this JS file uses this image

import { Accordion, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import speaker1 from "../images/palestrantes/antoni-dalmau.jpg" // Tell webpack this JS file uses this image
import CheckIcon from '@material-ui/icons/Check';
import foto1 from "../images/fotoscursos/foto1.png" // Tell webpack this JS file uses this image
import foto2 from "../images/fotoscursos/foto2.png" // Tell webpack this JS file uses this image
import foto3 from "../images/fotoscursos/foto3.png" // Tell webpack this JS file uses this image
import foto4 from "../images/fotoscursos/foto4.png" // Tell webpack this JS file uses this image
import foto5 from "../images/fotoscursos/foto5.png" // Tell webpack this JS file uses this image

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EuroIcon from '@material-ui/icons/Euro';

var settings = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
var settingsMobile = {
  infinite: true,
    autoplay:true,
    speed: 1000,
    autoplaySpeed:3000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows:false,
    centerMode:false,
    swipeToSlide: true,
    draggable:true,
};
const wqmatadouros = () => (
  <Layout>
<div id="section-privacidade"> 
    <div className="privacy-policy-container">
    <h1>Política de privacidade e reembolsos</h1>
      <div className="company-info">
     
        <h2>Informações da Empresa</h2>
        <p><strong>Nome:</strong> FARMIN - TRAINING AND CONSULTING, UNIPESSOAL LDA</p>
        <p><strong>NIF:</strong> PT515795895</p>
        <p><strong>Morada:</strong> Edifício Caçabrava S/N, 2305-101 Asseiceira - ASSEICEIRA TMR, Portugal</p>
      </div>

      <div className="policy-section">
        <h2>1. Âmbito da Nossa Política de Privacidade e Reembolsos</h2>
        <p>Esta política aplica-se a este website e a outros sites que mencionem a mesma política. A equipa da FARMIN respeita a privacidade dos utilizadores e processa os seus dados pessoais de acordo com as leis em vigor. Os dados não serão processados para fins que não os aqui especificados. A equipa da FARMIN reserva-se o direito de atualizar ou modificar a sua Política de Privacidade e Reembolsos a qualquer momento, particularmente para a adaptar a alterações legislativas.</p>
      </div>

      <div className="policy-section">
        <h2>2. Para Que Fins São Recolhidos os Dados Pessoais dos Utilizadores?</h2>
        <p>Os dados recolhidos através dos formulários do website destinam-se exclusivamente ao processamento de pedidos dos utilizadores e não serão usados para outros fins.</p>
      </div>

      <div className="policy-section">
        <h2>3. A Equipa da FARMIN Utiliza os Dados Pessoais Recolhidos para Marketing Direto?</h2>
        <p>A equipa da FARMIN não utilizará os seus dados pessoais para lhe enviar qualquer correio não solicitado sem o seu consentimento explícito. A equipa da FARMIN não vende nem comercializa os seus dados com terceiros.</p>
      </div>

      <div className="policy-section">
        <h2>4. Quem Tem Acesso aos Dados Pessoais dos Utilizadores?</h2>
        <p>A equipa da FARMIN não divulga quaisquer dados pessoais dos seus clientes e utilizadores a terceiros sem o seu consentimento, exceto quando exigido por lei.</p>
      </div>

      <div className="policy-section">
        <h2>5. Onde São Armazenados os Dados dos Utilizadores?</h2>
        <p>A equipa da FARMIN armazena os dados dos seus clientes e utilizadores nos seus servidores localizados na União Europeia. Estes servidores são protegidos e mantidos de acordo com os mais elevados padrões de segurança, de forma a cumprir as leis de privacidade aplicáveis. Caso deseje ser removido da nossa base de dados, poderá exercer esse direito, contactando-nos através dos vários meios disponíveis no website.</p>
      </div>

      <div className="policy-section">
        <h2>6. A Equipa da FARMIN Utiliza Cookies no Seu Website?</h2>
        <p>O website da FARMIN utiliza cookies em determinadas áreas. Os cookies são ficheiros que armazenam informações no disco rígido ou navegador do utilizador, permitindo que os websites o reconheçam e saibam que já os visitou anteriormente. O utilizador pode configurar o seu navegador para recusar cookies, mas, nesse caso, o website ou partes do mesmo podem não funcionar corretamente. Os cookies são utilizados para monitorizar e analisar a utilização do website, permitindo à equipa da FARMIN proporcionar a melhor experiência de utilização.</p>
      </div>

      <div className="policy-section">
        <h2>7. O Que Acontece em Caso de Aquisição da Empresa?</h2>
        <p>No caso de a FARMIN - TRAINING AND CONSULTING, UNIPESSOAL LDA ser adquirida ou fundida com outra empresa, os seus dados pessoais poderão ser transferidos para os novos proprietários para continuar a prestação de serviços. Qualquer transferência será feita em conformidade com as leis de privacidade aplicáveis e será comunicada aos utilizadores pelos canais apropriados.</p>
      </div>

      <div className="refund-policy-section">
        <h2>Política de Reembolsos e Cancelamentos</h2>
        <p>A política de reembolsos e cancelamentos da FARMIN - TRAINING AND CONSULTING, UNIPESSOAL LDA segue todas as regulamentações estipuladas pela União Europeia e pelo Brasil. Além disso, oferecemos condições específicas para cancelamentos de eventos.</p>
        <h3>Cancelamentos de Eventos e Reembolsos</h3>
        <h4>Política de Cancelamento:</h4>
        <p>A participação no evento pode ser cancelada até 30 dias antes da data do evento, com direito a reembolso total.<br></br>
Para cancelamentos feitos dentro dos 30 dias que antecedem o evento, não será efetuado reembolso.<br></br> No entanto, terá a opção de:<br></br>
Receber Crédito: O valor pago pode ser convertido em crédito para uma sessão de formação futura oferecida pela FARMIN.<br></br><br></br>
Transferir a Vaga: Pode transferir a sua vaga para outra pessoa, sujeito a aprovação prévia pela FARMIN.</p>

        <h3>União Europeia</h3>
        <h4>1. Introdução:</h4>
        <p>A nossa política de reembolsos para clientes residentes na União Europeia segue as regras previstas no Decreto-Lei n.º 24/2014, de 14 de fevereiro, adiante designado por "DL".</p>

        <h4>2. Direito de Resolução do Contrato:</h4>
        <p>Nos termos do DL, enquanto consumidor, tem o direito de resolver o contrato, ou seja, cancelar a compra, no prazo de 14 dias, contados a partir da data da compra.</p>

        <h4>3. Exclusão do Direito de Resolução:</h4>
        <p>Este direito é excluído quando a prestação do serviço digital já tiver começado com o consentimento prévio e explícito do consumidor, reconhecendo a perda do direito de resolver o contrato.
<br></br>Por exemplo, isto aplica-se às compras de cursos ou conteúdos que permitam ao consumidor o acesso imediato aos mesmos.<br></br>
Nesses casos, o consumidor é alertado previamente à compra de que esta implica o acesso imediato aos conteúdos e consequente perda do direito de resolução.</p>

        <h4>4. Como Exercer o Direito de Resolução:</h4>
        <p>Para exercer o seu direito de resolução, deve manifestá-lo expressamente à FARMIN - TRAINING AND CONSULTING, UNIPESSOAL LDA enviando um email para farmin@farmin-trainings.net.</p>

        <h4>5. Reembolso dos Pagamentos Efetuados pelo Consumidor:</h4>
        <p>Se o consumidor exercer o direito de resolução, a FARMIN reembolsará todos os montantes pagos pelo consumidor no prazo máximo de 14 dias.</p>

        <h4>6. Método de Pagamento para Reembolsos:</h4>
        <p>Os reembolsos serão efetuados utilizando o mesmo método de pagamento inicialmente usado pelo consumidor, salvo acordo em contrário.</p>

        <p>Para qualquer dúvida ou informação adicional, por favor, contacte a equipa da FARMIN através do email farmin@farmin-trainings.net.</p>
      </div>
    </div>
</div>
   

  </Layout>
)

export default wqmatadouros
